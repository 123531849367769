@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap');

@layer base {
  @font-face {
    font-family: 'ClashDisplay';
    src: url('../public/fonts/ClashDisplay-Variable.ttf') format('truetype-variations');
    font-weight: 100 900;
  }
}

html {
  background-color: #f8f5f2;
}

*:focus {
  outline-color: #f097e4;
  outline-offset: 0.5rem;
}